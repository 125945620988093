<template>
	<v-container>
		<v-breadcrumbs :items="breadcrumbs" class="mb-4">
			<template v-slot:divider>
				<v-icon>mdi-chevron-right</v-icon>
			</template>
		</v-breadcrumbs>

		<supplier-notifications-page />
	</v-container>
</template>
<script>
import SupplierNotificationsPage from '@/components/supplier/SupplierNotificationsPage/SupplierNotificationsPage.vue';
export default {
	name: 'SupplierNotificationsView',
	data() {
		return {
			breadcrumbs: [
				{
					text: 'Inicio',
					link: true,
					exact: true,
					disabled: false,
					to: {
						name: 'Dashboard',
					},
				},
				{
					text: 'Proveedor',
					link: true,
					exact: true,
					disabled: false,
					to: {
						name: 'SupplierHome',
					},
				},
				{
					text: 'Notificaciones',
					disabled: true,
					href: '/',
				},
			],
		};
	},
	components: { SupplierNotificationsPage },
};
</script>
